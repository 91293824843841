/* eslint-disable */
import Mapa from './Mapa.vue'

new Vue({
  el: '#mapa',
  template: '<Mapa/>',
  components: {
    Mapa
  }
})
