<template>
  <div id="mapa" class="captura-body-map">
    <div class="captura-body-map-container">
      <div class="captura-body-map-container-image">
        <div id="vmap" style="width: 100%; height: 100%;"></div>
      </div>
      <div class="captura-body-map-container-text">
        <div class="captura-body-map-container-text-title">
          <img src="../img/map.svg" alt="Símbolo do planeta Terra">
          <h2>ABRANGÊNCIA NACIONAL</h2>
        </div>
        <div class="captura-body-map-container-text-body">
          <span>
            Temos uma API de dados públicos para enriquecimento e saneamento de dados,
            que abrange captura de processos judiciais de todos os tribunais do país e emissão de certidões automatizadas junto à Receita Federal.
            Com sua tecnologia inovadora, o Captcha e Recaptcha deixam de ser um obstáculo, permitindo uma consulta muito mais célere
            e abrangente a plataformas como o PJe, eSAJ, Projudi, eProc e demais da área jurídica e comprovantes de situação cadastral
            de Pessoa Física e Jurídica.
          </span>
          <br>
          <br>
          <div v-if="region">
            <span
              class="captura-body-map-container-text-body-empty"
            >Tribunais suportados do estado de {{ region }}</span>
            <br class="captura-body-map-container-text-body-empty">
            <li v-for="item in regionInfo" :key="item">
              <span class="captura-body-map-container-text-body-empty">{{ item }}</span>
              <br class="captura-body-map-container-text-body-empty">
            </li>
          </div>
          <div v-else>
            <span
              class="captura-body-map-container-text-body-empty"
            >Selecione um estado para visualizar os tribunais suportados.</span>
          </div>
        </div>
        <!-- <div class="captura-body-map-container-list">
          <div class="captura-body-map-container-list-item">
            <h3>eSAJ</h3>
            <p>TJAC, TJAL, TJAM, TJBA, TJCE, TJMS, TJRN, TJSC, TJSP</p>
          </div>
          <div class="captura-body-map-container-list-item">
            <h3>Projudi</h3>
            <p>TJAM, TJBA, TJCE, TJDF, TJES, TJMA, TJMT, TJMG, TJPA, TJPB, TJPR, TJPI, TJRJ, TJRN, TJRO, TJRR, TJTO</p>
          </div>
          <div class="captura-body-map-container-list-item">
            <h3>PJe</h3>
            <p>TJBA, TJCE, TJDF, TJES, TJMA, TJMT, TJMG, TJPA, TJPB, TJPR, TJPE, TJPI, TJRJ, TJRN, TJRS, TJRO, TJRR, TRT1, TRT2, TRT3, TRT4, TRT5, TRT6, TRT7, TRT8, TRT9, TRT10, TRT11, TRT12, TRT13, TRT14, TRT15, TRT16, TRT17, TRT18, TRT19, TRT20, TRT21, TRT22, TRT23, TRT24</p>
          </div>
          <div class="captura-body-map-container-list-item">
            <h3>Eproc</h3>
            <p>TJRS, TJTO, TRF2, TRF4</p>
          </div>
          <div class="captura-body-map-container-list-item">
            <h3>Jurisconsult</h3>
            <p>TJMA</p>
          </div>
          <div class="captura-body-map-container-list-item">
            <h3>PEA</h3>
            <p>TJMT</p>
          </div>
          <div class="captura-body-map-container-list-item">
            <h3>Processos Físicos</h3>
            <p>TJMG, TRT2, TRT15</p>
          </div>
          <div class="captura-body-map-container-list-item">
            <h3>Outras Plataformas</h3>
            <p>TRF1, TRF3</p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import abrangencia from './abrangencia.json'

export default {
  name: 'Mapa',
  computed: {
    regionInfo () {
      //console.log('mapa.vue / computed\n');
      if (!this.info[this.region]) return []
      return (this.info[this.region])
    }
  },
  mounted () {
    //console.log('mapa.vue / mounted\n');
    if (jQuery(window).width() >= 768) {
      jQuery('#vmap').vectorMap({
        map: 'brazil_br',
        backgroundColor: null,
        color: '#2ceb94',
        hoverColor: '#5befac',
        selectedColor: '#13c775',
        enableZoom: false,
        showTooltip: true,
        onRegionClick: (element, code, region) => this.handleRegionClick(region)
      })
    }
  },
  methods: {
    //console.log('mapa.vue / methods\n');
    handleRegionClick (region) {
      this.region = (this.region === region) ? '' : region
    }
  },
  data() {
    //console.log('mapa.vue / data\n');
    return {
      region: '',
      info: abrangencia
    };
  }
};
</script>